import React from 'react'

export default function Loader() {
    window.scroll(0,0)
  return (
    <div>
      <div className=' model-popup'>
            <div className=' loader'>

            </div>
      </div>
    </div>
  )
}
